@import '../../../../styles/mixins';
@import '../../../../styles/mixins_table';

@include common-table;

.BroadSignCreationsTable {
  &__no-data {
    display: flex;
    padding: 15px;
    justify-content: center;
  }

  &__icon-row.BroadSignCreationsTable__icon-row {
    width: 150px;
    max-width: 150px;
  }

  &__preview {
    max-width: 50px;
  }

  &__table {
    @include scrollbar(12px);
    overflow: auto;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .TableHeaderItem {
      white-space: normal;
      padding-bottom: 10px;

      &:last-child {
        padding-right: 15px;
      }
    }

    .TableData {
      white-space: nowrap;
      padding-bottom: 5px;
    }
  }
}
