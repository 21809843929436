@import 'styles/colors';

@mixin chart-legend($padding-left) {
  &__legend {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: $color-gray-4;
    padding: 10px 0 0 #{$padding-left}px;

    &--item {
      display: flex;
      align-items: center;
      padding-bottom: 15px;

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    &--symbol {
      width: 19px;
      margin-right: 10px;
      border-radius: 2px;
      height: 12px;
    }
  }
}

@mixin axis-tick() {
  &__axis-tick {
    fill: $color-gray-4;
    font-size: 11px;
  }
}

@mixin tooltip() {
  &__tooltip {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.5);
    font-size: 11px;
    color: $color-gray-5;
    padding: 10px;

    &--header {
      font-weight: 600;

      padding-top: 15px;
      &:first-child {
        padding-top: 0px;
      }
    }

    &--row {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
    }

    &--col {
      min-width: max-content;

      &:last-of-type {
        display: flex;
        justify-content: flex-end;
        padding-left: 25px;
      }
    }
  }
}
