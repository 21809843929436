@import 'styles/mixins';

.Broadsign {
  &__container {
    overflow: hidden;
    width: calc(100vw - 340px);
    height: 100%;

    @media (max-width: 1020px) {
      width: calc(100vw - 284px);
    }
  }

  &--loading {
    padding: 15px 0;
  }
}
