.BroadSignVideoPreviewModal {
  .Modal__content {
    max-width: 1024px;
    width: 100%;
    margin: 20px;
    max-height: 80vh;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__video {
    max-height: 550px;
  }
}
