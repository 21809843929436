@import 'styles/colors';
@import 'styles/constants';

.BroadSignAddModal {
  width: 400px;
  min-height: 180px;
  max-height: calc($modal-max-height + 120px);

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    .Checkbox__title {
      padding-left: 7px;
    }
  }
}

.BroadSignAddModalForm {
  &__deal {
    padding: 0;
  }

  &__campaign {
    padding: 30px 0 20px 0;
  }
}
