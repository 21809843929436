@import 'styles/colors';

.BroadSignCreationModal {
  .Modal__content {
    max-width: 1024px;
    width: 100%;
    margin: 20px;
    max-height: 95vh;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__image-container {
    position: relative;
  }

  &__image {
    max-height: 400px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  &__header {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 550px;
  }

  &__play-icon {
    font-size: 50px;
    color: $color-blue;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__details-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 26px;

    // this is just a first column
    p:nth-child(odd) {
      font-weight: bold;
    }
  }
}
