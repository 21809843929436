@import 'styles/colors';
@import 'styles/mixins';

.BroadSignFilters {
  &__search-row {
    @include table-search-row();
  }

  &__container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    // every element below is a child of .BroadSignFilters__container
    & > * {
      flex-grow: 1;
    }

    .SingleDatePicker, .DateInput, .DateInput_input, .SingleDatePickerInput {
        width: 100%;
    }

    .SelectMultiCheckbox__control {
      // For some unknown reason inner SelectMultiCheckbox contains !important
      // so this is the only way to override it and example why not to use it
      min-height: 50px !important;
    }
  }

    &__submit-button {
      flex-grow: 0;
        margin-top: 20px;
    }
}
