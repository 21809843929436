.BroadSignRejectCreationModal {
  .Modal__content {
    max-width: 600px;
    width: 100%;
    margin: 20px;
    max-height: 95vh;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__input {
    box-sizing: border-box;
    min-height: 80px;
    padding: 5px 10px;
    width: 100%;
  }
}
